<template>
  <Layout>
    <div class="alertMsg">
      <b-alert v-model="showDismissibleAlert" dismissible variant="success">
        Restaurant Branch Registered Successfully!
      </b-alert>
    </div>

    <PageHeader :items="items" :title="title" />

    <form
      autocomplete="off"
      method="post"
      @submit.prevent="submitRestaurantBrachForm"
    >
      <div class="row">
        <div class="col-lg-12">
          <div class="card">
            <div class="card-body">
              <div class="row mb-3">
                <div class="col-md-6">
                  <label class="form-label" for="formrow-restType-input"
                    >Restaurant Type*</label
                  >
                  <multiselect
                    v-model="posts.restType"
                    :class="{
                      'is-invalid': submitted && $v.posts.restType.$error
                    }"
                    :options="restTypeArr"
                    label="typeName"
                    track-by="restTypeID"
                  ></multiselect>
                  <div
                    v-if="submitted && $v.posts.restType.$error"
                    class="invalid-feedback"
                  >
                    <span v-if="!$v.posts.restType.required"
                      >This value is required.</span
                    >
                  </div>
                </div>
                <!-- <div v-if="posts.restType.restTypeID===2" class="col-md-6">
                 <label class="form-label" for="formrow-endTime-input">Select Corporate Branch </label>
                  <multiselect v-model="posts.corpBranchID" :options="corporates" track-by="corpBranchID" label="corpBranchName"
                 ></multiselect>
               </div> -->
              </div>
              <div class="row">
                <div class="col-md-6 mb-3">
                  <label>Choose Restaurant Brand* </label>
                  <multiselect
                    v-model="posts.restaurantID"
                    :class="{
                      'is-invalid': submitted && $v.posts.restaurantID.$error
                    }"
                    :options="restaurants"
                    label="name"
                    track-by="restID"
                  ></multiselect>
                  <div
                    v-if="submitted && $v.posts.restaurantID.$error"
                    class="invalid-feedback"
                  >
                    <span v-if="!$v.posts.restaurantID.required"
                      >This value is required.</span
                    >
                  </div>
                </div>

                <div class="col-md-6">
                  <label>Location *</label>
                  <multiselect
                    v-model="posts.branchIDSel"
                    :class="{
                      'is-invalid': submitted && $v.posts.branchIDSel.$error
                    }"
                    :options="branches"
                    label="branchName"
                    track-by="branchID"
                  ></multiselect>
                  <div
                    v-if="submitted && $v.posts.branchIDSel.$error"
                    class="invalid-feedback"
                  >
                    <span v-if="!$v.posts.branchIDSel.required"
                      >This value is required.</span
                    >
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="mb-3 col-md-6">
                  <label>Address* </label>
                  <vue-google-autocomplete
                    id="map"
                    ref="address"
                    v-model="posts.address"
                    :class="{
                      'is-invalid': submitted && $v.posts.addressInput.$error
                    }"
                    autocomplete="off"
                    classname="form-control"
                    country="in"
                    placeholder="Please type your address"
                    types="establishment"
                    v-on:placechanged="getAddressData"
                  >
                  </vue-google-autocomplete>
                  <div
                    v-if="submitted && $v.posts.addressInput.$error"
                    class="invalid-feedback"
                  >
                    <span v-if="!$v.posts.addressInput.required"
                      >This value is required.</span
                    >
                  </div>
                </div>
                <div
                  v-if="this.posts.addressInput"
                  class="col-md-4"
                  style="border: 1px solid #d8d8d8;padding: 10px 28px;"
                >
                  <p>
                    {{ this.posts.addressInput }}

                    <span style="position: absolute;right: 3px;bottom: -7px;">
                      <button
                        v-b-modal.modal-standard
                        class="btn"
                        style="color: #F3766A !important;font-size: 15px;font-weight: 600;"
                        type="button"
                      >
                        <i class="uil uil-plus"></i> Edit
                      </button>
                    </span>
                  </p>
                </div>
              </div>

              <b-modal
                id="modal-standard"
                title="Restaurant Address"
                title-class="font-18"
                @ok="addNewAddress()"
              >
                <div class="mb-3">
                  <label>Address</label>

                  <textarea
                    v-model="posts.addressInput"
                    class="form-control"
                    name="textarea"
                    rows="3"
                  ></textarea>
                </div>
                <div class="row">
                  <div class="col-md-6 mb-3">
                    <label class="form-label" for="formrow-pincode-input"
                      >Pincode</label
                    >
                    <input
                      id="formrow-pincode-input"
                      v-model="posts.pincode"
                      class="form-control"
                      type="text"
                    />
                  </div>
                  <div class="col-md-6 mb-3">
                    <label class="form-label" for="formrow-city-input"
                      >City</label
                    >
                    <input
                      id="formrow-city-input"
                      v-model="posts.city"
                      class="form-control"
                      type="text"
                    />
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-6 mb-3">
                    <label class="form-label" for="formrow-state-input"
                      >State</label
                    >
                    <input
                      id="formrow-state-input"
                      v-model="posts.state"
                      class="form-control"
                      type="text"
                    />
                  </div>
                  <div class="col-md-6 mb-3">
                    <label class="form-label" for="formrow-country-input"
                      >Country</label
                    >
                    <input
                      id="formrow-country-input"
                      v-model="posts.country"
                      class="form-control"
                      type="text"
                    />
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-6 mb-3">
                    <label class="form-label" for="formrow-latitude-input"
                      >Latitude</label
                    >
                    <input
                      id="formrow-latitude-input"
                      v-model="posts.latitude"
                      class="form-control"
                      type="text"
                    />
                  </div>
                  <div class="col-md-6 mb-3">
                    <label class="form-label" for="formrow-longitude-input"
                      >Longitude</label
                    >
                    <input
                      id="formrow-longitude-input"
                      v-model="posts.longitude"
                      class="form-control"
                      type="text"
                    />
                  </div>
                </div>
              </b-modal>
              <div class="row mb-3">
                <div class="col-md-6">
                  <label
                    class="form-label"
                    for="formrow-avgPreparationTime-input"
                    >Avg Preparation Time*</label
                  >
                  <multiselect
                    v-model="posts.avgPreparationTime"
                    :class="{
                      'is-invalid':
                        submitted && $v.posts.avgPreparationTime.$error
                    }"
                    :options="deliveryTimeArr"
                    label="label"
                    track-by="time"
                  >
                  </multiselect>

                  <div
                    v-if="submitted && $v.posts.avgPreparationTime.$error"
                    class="invalid-feedback"
                  >
                    <span v-if="!$v.posts.avgPreparationTime.required"
                      >This value is required.</span
                    >
                  </div>
                </div>

                <!-- <div class="mb-3 col-6">
                 <label class="form-label" for="formrow-charges-input">Restaurant Charges</label>
                 <div class="input-group"><div class="input-group-text">₹</div>
                   <input type="text" class="form-control" id="formrow-charges-input" v-model="posts.restCharges">
                 </div>
               </div> -->
              </div>
              <!-- <div class="row mb-3">
                  <div class="col-md-6">
                   <label class="form-label" for="formrow-starttime-input">Start Time*</label>
                      <input class="form-control" type="time" value="13:45:00"  v-model="posts.startTime">
                  </div>
                   <div class="col-md-6">
                    <label class="form-label" for="formrow-endTime-input">End Time*</label>
                      <input class="form-control" type="time" value="13:45:00" v-model="posts.endTime">
                  </div>
              </div> -->
              <div class="row">
                <div class="mb-3 col-md-6">
                  <label class="form-label" for="formrow-restName-input"
                    >Invoice Number Prefix * </label
                  ><span>
                    Invoice Number will start as:
                    <span style="text-transform: uppercase;">{{
                      posts.invoicePrefix != "" ? posts.invoicePrefix : "CK"
                    }}</span
                    >000001</span
                  >
                  <input
                    id="formrow-invoicePrefix-input"
                    v-model="posts.invoicePrefix"
                    :class="{
                      'is-invalid': submitted && $v.posts.invoicePrefix.$error
                    }"
                    class="form-control"
                    name="invoicePrefix"
                    style="text-transform: uppercase;"
                    type="text"
                  />
                  <div
                    v-if="submitted && $v.posts.invoicePrefix.$error"
                    class="invalid-feedback"
                  >
                    <span v-if="!$v.posts.invoicePrefix.required"
                      >This value is required.</span
                    >
                  </div>
                </div>

                <div class="mb-3 col-md-6">
                  <label class="form-label" for="formrow-restName-input"
                    >Invoice Footnote</label
                  >
                  <input
                    id="formrow-invoiceMessage-input"
                    v-model="posts.invoiceMessage"
                    class="form-control"
                    name="invoiceMessage"
                    type="text"
                  />
                </div>
              </div>

              <div class="row mb-3">
                <div class="mb-3 col-6">
                  <label class="form-label" for="formrow-gstNo-input"
                    >GST Number*</label
                  >
                  <input
                    id="formrow-gstNO-input"
                    v-model="posts.gstNO"
                    :class="{
                      'is-invalid': submitted && $v.posts.gstNO.$error
                    }"
                    class="form-control"
                    style="text-transform: uppercase;"
                    type="text"
                  />
                  <div
                    v-if="submitted && $v.posts.gstNO.$error"
                    class="invalid-feedback"
                  >
                    <span v-if="!$v.posts.gstNO.required"
                      >This value is required.</span
                    >
                  </div>
                </div>

                <div class="mb-3 col-6">
                  <label class="form-label" for="formrow-gstNo-input"
                    >Registered Name as per GST</label
                  >
                  <input
                    id="formrow-gstName-input"
                    v-model="posts.gstName"
                    class="form-control"
                    type="text"
                  />
                </div>
              </div>

              <div class="row mb-4">
                <div class="mb-1 col-6">
                  <input
                    id="autoSizingCheck2"
                    v-model="posts.isPublicOrder"
                    class="form-check-input"
                    type="checkbox"
                  />
                  <label
                    class="form-check-label"
                    for="autoSizingCheck2"
                    style="margin-left: 5px;"
                  >
                    Available for public orders
                  </label>
                  <p>
                    This restaurant will receive the orders from any customer in
                    nearby locations. If you want to limit the ordering for some
                    organization only, please uncheck this.
                  </p>
                </div>
                <div class="mb-1 col-6">
                  <div class="row">
                    <div class="col-12">
                      <div
                        class="form-check form-switch form-switch-md mb-2"
                        dir="ltr"
                      >
                        <input
                          id="switchSodexo"
                          v-model="posts.isSodexoApproved"
                          class="form-check-input"
                          type="checkbox"
                        />
                        <label class="form-check-label" for="switchSodexo"
                          >Activate Sodexo Acceptance</label
                        >
                      </div>
                    </div>
                    <div class="col-12 mt-1">
                      <div class="form-group row">
                        <label class="col-md-2 col-form-label" for="mid"
                          >MID</label
                        >
                        <div class="col-md-10">
                          <input
                            id="mid"
                            v-model="posts.mid"
                            class="form-control"
                            type="text"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-12 mt-1">
                      <div class="form-group row">
                        <label class="col-md-2 col-form-label" for="tid"
                          >TID</label
                        >
                        <div class="col-md-10">
                          <input
                            id="tid"
                            v-model="posts.tid"
                            class="form-control"
                            type="text"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 mb-1">
                  <label class="form-label" for="payu-qr">PayU Static QR</label>
                  <div class="form-group">
                    <input
                      id="payu-qr"
                      v-model="posts.payu_qr_id"
                      class="form-control"
                      type="text"
                    />
                  </div>
                </div>
                <div class="mb-1 col-md-6">
                  <div class="row mb-1">
                    <div class="col-md-12">
                      <label>Revenue Share in %</label>
                      <div class="form-group">
                        <input
                          v-model="posts.revenue_share"
                          class="form-control"
                          min="0"
                          step="0.1"
                          type="number"
                        />
                      </div>
                    </div>
                    <div class="col-md-12 mt-2">
                      <label>Select Settlement Cycle Type *</label>
                      <multiselect
                        v-model="posts.settlementCycle"
                        :options="posts.settlementCycles"
                        label="settlement_type"
                        track-by="id"
                      ></multiselect>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
<!--      <div class="col-lg-12">-->
<!--        <div class="card">-->
<!--          <div class="card-body">-->
<!--            <h6 class="mb-3">Admin Details</h6>-->
<!--            <div class="col-md-12 mb-3">-->
<!--              <label>Select Branch Manager* </label>-->
<!--              <multiselect-->
<!--                v-model="posts.branchManagerID"-->
<!--                :class="{-->
<!--                  'is-invalid': submitted && $v.posts.branchManagerID.$error-->
<!--                }"-->
<!--                :disabled="disabledOwner"-->
<!--                :options="branchManager"-->
<!--                label="name"-->
<!--                track-by="restEmpID"-->
<!--              ></multiselect>-->
<!--              <div-->
<!--                v-if="submitted && $v.posts.branchManagerID.$error"-->
<!--                class="invalid-feedback"-->
<!--              >-->
<!--                <span v-if="!$v.posts.branchManagerID.required"-->
<!--                  >This value is required.</span-->
<!--                >-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="mb-4">-->
<!--              <input-->
<!--                id="autoSizingCheck3"-->
<!--                v-model="posts.empType"-->
<!--                class="form-check-input"-->
<!--                type="checkbox"-->
<!--                @change="registerNew()"-->
<!--              />-->
<!--              <label-->
<!--                class="form-check-label"-->
<!--                for="autoSizingCheck3"-->
<!--                style="margin-left: 5px;"-->
<!--              >-->
<!--                or Register New-->
<!--              </label>-->
<!--            </div>-->

<!--            <div class="row">-->
<!--              <div class="mb-3 col-md-6">-->
<!--                <label class="form-label" for="formrow-branchManager-input"-->
<!--                  >Admin Name *</label-->
<!--                >-->
<!--                <input-->
<!--                  id="formrow-branchManager-input"-->
<!--                  v-model="posts.branchManName"-->
<!--                  :class="{-->
<!--                    'is-invalid': submitted && $v.posts.branchManName.$error-->
<!--                  }"-->
<!--                  :disabled="disabled"-->
<!--                  class="form-control"-->
<!--                  type="text"-->
<!--                />-->
<!--                <div-->
<!--                  v-if="submitted && $v.posts.branchManName.$error"-->
<!--                  class="invalid-feedback"-->
<!--                >-->
<!--                  <span v-if="!$v.posts.branchManName.required"-->
<!--                    >This value is required.</span-->
<!--                  >-->
<!--                </div>-->
<!--              </div>-->

<!--              <div class="mb-3 col-md-6">-->
<!--                <label class="form-label" for="formrow-branchManPhoneNO-input"-->
<!--                  >Admin Mobile No *</label-->
<!--                >-->
<!--                <input-->
<!--                  id="formrow-branchManPhoneNO-input"-->
<!--                  v-model="posts.branchManMob"-->
<!--                  :class="{-->
<!--                    'is-invalid': submitted && $v.posts.branchManMob.$error-->
<!--                  }"-->
<!--                  :disabled="disabled"-->
<!--                  class="form-control"-->
<!--                  type="text"-->
<!--                />-->
<!--                <div-->
<!--                  v-if="submitted && $v.posts.branchManMob.$error"-->
<!--                  class="invalid-feedback"-->
<!--                >-->
<!--                  <span v-if="!$v.posts.branchManMob.required"-->
<!--                    >This value is required.</span-->
<!--                  >-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="mb-3 col-md-6">-->
<!--              <label class="form-label" for="formrow-branchManagerEmail-input"-->
<!--                >Admin Email ID *</label-->
<!--              >-->
<!--              <input-->
<!--                id="formrow-branchManagerEmail-input"-->
<!--                v-model="posts.branchManEmail"-->
<!--                :class="{-->
<!--                  'is-invalid': submitted && $v.posts.branchManEmail.$error-->
<!--                }"-->
<!--                :disabled="disabled"-->
<!--                class="form-control"-->
<!--                type="text"-->
<!--              />-->
<!--              <div-->
<!--                v-if="submitted && $v.posts.branchManEmail.$error"-->
<!--                class="invalid-feedback"-->
<!--              >-->
<!--                <span v-if="!$v.posts.branchManEmail.required"-->
<!--                  >This value is required.</span-->
<!--                >-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <h6>Restaurant timings</h6>
            <div class="row">
              <div class="col-md-2"></div>
              <div class="col-md-2">
                <label class="form-label" for="formrow-starttime-input"
                  >Start Time*</label
                >
              </div>
              <div class="col-md-2">
                <label class="form-label" for="formrow-starttime-input"
                  >End Time*</label
                >
              </div>
              <div class="col-md-2">
                <label class="form-label" for="formrow-starttime-input"
                  >Closed*</label
                >
              </div>
            </div>
            <div
              v-for="(data, index) in dayArr"
              :key="data.id"
              class="row"
              style="margin-bottom: 16px;"
            >
              <div class="col-md-2">
                <label> {{ data.name }} </label>
              </div>
              <div class="col-md-2">
                <input
                  id="example-time-input-start"
                  v-model="posts.startTime[index]"
                  :disabled="data.disabled"
                  class="form-control"
                  type="time"
                  value="13:45:00"
                />
              </div>
              <div class="col-md-2">
                <input
                  id="example-time-input-end"
                  v-model="posts.endTime[index]"
                  :disabled="data.disabled"
                  class="form-control"
                  type="time"
                  value="13:45:00"
                />
              </div>
              <div class="col-md-2">
                <div
                  class="form-check form-switch form-switch-md mb-3"
                  dir="ltr"
                >
                  <input
                    id="customSwitchsizemd"
                    v-model="posts.closed[index]"
                    class="form-check-input"
                    type="checkbox"
                    @change="isClosed(index)"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-sm-6">
                <div class="mb-3">
                  <label for="metatitle">Meta title</label>
                  <input
                    id="metatitle"
                    v-model="posts.metaTitle"
                    :maxlength="30"
                    class="form-control"
                    name="metatitle"
                    type="text"
                  />
                  <p
                    v-if="posts.metaTitle"
                    :class="{
                      'bg-success': posts.metaTitle.length !== 30,
                      'bg-danger': posts.metaTitle.length === 30
                    }"
                    class="badge position-absolute"
                    style="right: 9px;"
                  >
                    {{ posts.metaTitle.length }} / 30
                  </p>
                </div>
              </div>

              <div class="col-sm-6">
                <div class="mb-3">
                  <label for="metakeywords">Meta Keywords</label>
                  <input
                    id="metakeywords"
                    v-model="posts.metaKeywords"
                    class="form-control"
                    name="metakeywords"
                    type="text"
                  />
                </div>
              </div>
            </div>

            <div class="mb-3 mb-0">
              <label for="metadescription">Meta Description</label>
              <textarea
                id="metadescription"
                v-model="posts.metaDescription"
                :maxlength="160"
                class="form-control"
                rows="4"
              ></textarea>
              <p
                v-if="posts.metaDescription"
                :class="{
                  'bg-success': posts.metaDescription.length !== 160,
                  'bg-danger': posts.metaDescription.length === 160
                }"
                class="badge position-absolute"
                style="right: 9px;"
              >
                {{ posts.metaDescription.length }} / 160
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row mb-3">
              <div class=" col-md-6">
                <label class="form-label" for="formrow-licenseNO-input"
                  >License document upload</label
                ><br />
                <div class="file-upload-form">
                  Choose File
                  <input
                    class="uploadInput"
                    type="file"
                    @change="previewImage"
                  />
                </div>
                {{ previewFileName }}
                <div v-if="imageData.length > 0" class="image-preview col-md-3">
                  <img
                    v-if="docExt != 'pdf'"
                    :src="imageData"
                    class="preview"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <label class="form-label" for="formrow-licenseNO-input"
                  >FSSAI License Number*</label
                >
                <input
                  id="formrow-licenseNO-input"
                  v-model="posts.licenseNO"
                  :class="{
                    'is-invalid': submitted && $v.posts.licenseNO.$error
                  }"
                  class="form-control"
                  type="text"
                />
                <div
                  v-if="submitted && $v.posts.licenseNO.$error"
                  class="invalid-feedback"
                >
                  <span v-if="!$v.posts.licenseNO.required"
                    >This value is required.</span
                  >
                </div>
              </div>
            </div>
            <div class="col-md-4 mb-3">
              <label>Expiry Date</label>
              <br />
              <input
                id="formrow-licenseNO-input"
                v-model="autoClose"
                :min="nowDate"
                class="form-control"
                type="date"
              />
              <!-- <date-picker v-model="autoClose" :first-day-of-week="1" lang="en"></date-picker> -->
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <h6 class="mb-3">Other Documents</h6>
            <div class="inner-repeater mb-4">
              <div class="inner mb-3">
                <div class="row">
                  <label class="col-md-3">Document Name :</label>
                  <label class="col-md-3">Document Number</label>
                  <label class="col-md-3">Date of Expiry:</label>
                  <label class="col-md-2">Upload Document:</label>
                </div>

                <div
                  v-for="(data, index) in otherDocs"
                  :key="data.id"
                  class="inner mb-3 row"
                >
                  <div class="col-md-3">
                    <multiselect
                      v-model="data.documentName"
                      :options="documentNameArr"
                      :show-labels="false"
                    ></multiselect>
                  </div>
                  <div class="col-md-3">
                    <input
                      id="formrow-documentNO-input"
                      v-model="data.documentNO"
                      class="form-control"
                      type="text"
                    />
                  </div>
                  <div class="col-md-3">
                    <input
                      id="formrow-documentExpiry-input"
                      v-model="data.documentExpiry"
                      :min="nowDate"
                      class="form-control"
                      type="date"
                    />
                  </div>
                  <div class="col-md-2">
                    <div class="file-upload-form">
                      Choose File
                      <input
                        class="uploadInput"
                        type="file"
                        @change="uploadImage($event, index)"
                      />
                    </div>
                    {{ data.docFileName }}
                    <!-- accept="image/*" -->

                    <div
                      v-if="data.documentImage && data.documentImage.length > 0"
                      class="image-preview col-md-3"
                    >
                      <img :src="data.documentImage" class="preview" />
                    </div>
                  </div>
                  <div class="col-sm-1 align-self-center d-grid">
                    <input
                      class="btn btn-themeBrown btn-block inner"
                      type="button"
                      value="Delete"
                      @click="deleteItem(index)"
                    />
                  </div>
                </div>
              </div>
              <input
                class="btn btn-themeOrange inner"
                type="button"
                value="Add new item"
                @click="AddItem"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <h6 class="mb-3">Bank Account Details</h6>
            <div class="row">
              <div class="col-md-6">
                <label class="form-label" for="formrow-bankName-input"
                  >Bank Name</label
                >
                <input
                  id="formrow-bankName-input"
                  v-model="posts.bankName"
                  class="form-control"
                  type="text"
                />
              </div>
              <div class="col-md-6">
                <label class="form-label" for="formrow-accountNO-input"
                  >Account Number</label
                >
                <input
                  id="formrow-accountNO-input"
                  v-model="posts.accountNO"
                  class="form-control"
                  type="text"
                />
              </div>
            </div>
            <div class="col-md-6">
              <label class="form-label" for="formrow-ifscCode-input"
                >IFSC Code</label
              >
              <input
                id="formrow-ifscCode-input"
                v-model="posts.ifscCode"
                class="form-control"
                type="text"
              />
            </div>
            <div class=" row" style="float:right;">
              <b-spinner
                v-if="loading"
                class="m-2 col-3"
                role="status"
                variant="primary"
              ></b-spinner>
              <button
                  v-if="$can('create restaurant branch') && !isEdit"
                :disabled="loading"
                class="col-3 btn btn-themeYellow w-md waves-effect waves-light"
                type="button"
                @click="
                  submitRestaurantBrachForm()
                "
              >
                Submit
              </button>
              <button
                :disabled="loading"
                v-if="$can('update restaurant branch') && isEdit"
                class="col-3 btn btn-themeYellow w-md waves-effect waves-light"
                type="button"
                @click="updateRestaurantBrachForm()
                "
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
    <!-- Success circle button with ripple effect -->
  </Layout>
</template>
<script>
import Multiselect from "vue-multiselect";
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import helperFile from "../../../helpers/helper";
import {
  required
} from "vuelidate/lib/validators";

/**
 * Advanced-form component
 */
export default {
  page: {
    title: "Add Branch",
    meta: [
      {
        name: "description",
        content: appConfig.description
      }
    ]
  },
  components: {
    Multiselect,
    Layout,
    PageHeader,
    VueGoogleAutocomplete
  },
  data() {
    return {
      title: "Add Restaurant Branch",
      items: [
        {
          text: "Restaurants Branch",
          href: "/"
        },
        {
          text: "Add",
          active: true
        }
      ],

      branchName: null,
      branchIDSel: null,
      status: "",
      submitted: false,
      address: "",
      timeDisabled: false,
      autoClose: "",

      mindate: "01/07/2021",
      isEdit: false,
      disabled: true,
      disabledOwner: false,
      restaurants: [],
      branchManager: [],
      branches: [],
      textarea: "",
      imageData: "",
      prevLicenseDoc: "",
      docExt: "",
      previewFileName: "",
      tagline: null,

      otherDocs: [
        {
          id: 0,
          documentNO: "",
          documentImage: 0,
          documentName: "",
          documentExpiry: "",
          docFileName: ""
        }
      ],

      documentNameArr: [
        "COMPANY PAN",
        "INCOME TAX",
        "SHOP & ESTABLISHMENTS LICENSE",
        "LABOR LICENSE",
        "ISO CERTIFIED 22000-2005",
        "GST TIN",
        "ISO CERTIFIED 9001-2008",
        "PF LICENSE",
        "ESIC LICENSE",
        "MLW LICENSE",
        "SERVICE TAX",
        "SALE TAX VAT REGISTRATION",
        "HACCP (CERTIFIED)",
        "LBT (CERTIFICATE)",
        "COMPANY TAN"
      ],

      deliveryTimeArr: [
        { label: "5 mins", time: 5 },
        { label: "10 mins", time: 10 },
        { label: "15 mins", time: 15 },
        { label: "20 mins", time: 20 },
        { label: "25 mins", time: 25 },
        { label: "30 mins", time: 30 },
        { label: "35 mins", time: 35 },
        { label: "40 mins", time: 40 },
        { label: "45 mins", time: 45 },
        { label: "50 mins", time: 50 },
        { label: "55 mins", time: 55 },
        { label: "60 mins", time: 60 }
      ],
      restTypeArr: [
        { restTypeID: 1, typeName: "Restaurant" },
        { restTypeID: 2, typeName: "Cafeteria" }
      ],
      corporates: [],
      showDismissibleAlert: false,
      loading: false,
      encodeImage: "",
      dayArr: [
        { name: "Monday", disabled: false },
        { name: "Tuesday", disabled: false },
        { name: "Wednesday", disabled: false },
        { name: "Thursday", disabled: false },
        { name: "Friday", disabled: false },
        { name: "Saturday", disabled: false },
        { name: "Sunday", disabled: false }
      ],
      avgPreparationTime: "",
      disabledTime: false,
      nowDate: new Date().toISOString().slice(0, 10),
      posts: {
        branchIDSel: null,
        restaurantID: null,
        managerEmailID: "",
        managerPhoneNO: "",
        managerName: "",
        address: "",
        addressInput: "",
        state: "",
        country: "",
        pincode: "",
        city: "",
        latitude: "",
        longitude: "",
        licenseNO: "",
        endTime: [
          "12:00",
          "12:00",
          "12:00",
          "12:00",
          "12:00",
          "12:00",
          "12:00"
        ],
        startTime: [
          "12:00",
          "12:00",
          "12:00",
          "12:00",
          "12:00",
          "12:00",
          "12:00"
        ],
        closed: ["", "", "", "", "", "", ""],
        imageUrl: "",
        restType: "",
        corpBranchID: "",
        isPublicOrder: true,
        restCharges: "",
        bankName: "",
        accountNO: "",
        ifscCode: "",
        branchManEmail: "",
        branchManMob: "",
        branchManName: "",
        empType: false,
        branchManagerID: [],
        metaTitle: "",
        metaDescription: "",
        metaKeywords: "",
        invoicePrefix: "",
        invoiceMessage: "",
        gstNO: "",
        gstName: "",
        avgPreparationTime: "",
        otherDocs: [],
        isSodexoApproved: false,
        mid: "",
        tid: "",
        payu_qr_id: "",
        revenue_share: "",
        settlementCycle: "",
        settlementCycles: []
      }
    };
  },

  validations: {
    posts: {
      restaurantID: {
        required
      },
      branchIDSel: {
        required
      },
      avgPreparationTime: {
        required
      },
      startTime: {
        required
      },
      endTime: {
        required
      },
      addressInput: {
        required
      },
      invoicePrefix: {
        required
      },
      // invoiceMessage:{
      //   required,
      // },
      gstNO: {
        required
      },
      licenseNO: {
        required
      },
      restType: {
        required
      },
      // branchManagerID: {
      //   required: function() {
      //     return this.posts.empType == false && this.posts.branchManagerID == ""
      //       ? false
      //       : true;
      //   }
      // },
      // branchManMob: {
      //   required: function() {
      //     return this.posts.empType == true && this.posts.branchManMob == ""
      //       ? false
      //       : true;
      //   },
      //   maxLength: maxLength(10),
      //   minLength: minLength(10)
      // },
      // branchManEmail: {
      //   required: function() {
      //     return this.posts.empType == true && this.posts.branchManEmail == ""
      //       ? false
      //       : true;
      //   },
      //   email
      // },
      // branchManName: {
      //   required: function() {
      //     return this.posts.empType == true && this.posts.branchManName == ""
      //       ? false
      //       : true;
      //   }
      // }
    }
  },
  methods: {
    isClosed(index) {
      this.dayArr[index].disabled = !this.dayArr[index].disabled;
      this.posts.startTime[index] = "";
      this.posts.endTime[index] = "";
    },
    /**
     * When the location found
     * @param {Object} addressData Data of the found location
     * @param {Object} placeResultData PlaceResult object
     * @param {String} id Input container ID
     */
    getAddressData: function(addressData, placeResultData) {
      this.address = addressData;
      if (placeResultData) {
        this.getAllFields = helperFile.getAddressFieldsFromPlacesApi;
        this.getAllFields(placeResultData);
        //console.log(placeResultData);
        this.posts.addressInput = placeResultData.formatted_address;
        this.posts.latitude = placeResultData.geometry.location.lat();
        this.posts.longitude = placeResultData.geometry.location.lng();
      }
    },
    addNewAddress() {
      this.posts.address = this.posts.addressInput;
    },
    getSettlementCycleTypes() {
      this.axios
        .get(this.$loggedRole+"/get-settlement-cycle-types")
        .then(response => {
          if (response.data.status == 200) {
            this.posts.settlementCycles = response.data.data;
          } else {
            alert(response.data.message);
          }
        })
        .catch(error => {
          this.$swal({
            title: 'Oops...',
            text: error.response.data.message,
            icon: "error",
          });

        });
    },
    getEmployees() {
      this.branchManager = [];

      this.axios
        .post(this.$loggedRole+"/getEmployeesByRestID", {
          restID: this.posts.restaurantID.restID
        })
        .then(result => {
          this.branchManager = result.data.data;
        });
    },
    getCorporateList() {
      this.axios.get(this.$loggedRole+"/getCorporateBranchList").then(result => {
        this.corporates = result.data.data;
      });
    },
    registerNew() {
      // this.posts.showAlert = 1;
      if (this.disabled) {
        this.disabled = false;
        this.disabledOwner = true;
        this.posts.branchManagerID = "";
      } else {
        this.disabled = true;
        this.disabledOwner = false;
        this.posts.branchManEmail = "";
        this.posts.branchManMob = "";
        this.posts.branchManName = "";
      }
    },
    submitRestaurantBrachForm() {
      if (this.posts.isSodexoApproved) {
        if (
          this.posts.mid == "" ||
          this.posts.mid.length == 0 ||
          this.posts.mid == "undefined" ||
          this.posts.tid == "" ||
          this.posts.tid.length == 0 ||
          this.posts.tid == "undefined"
        ) {
          alert("Please enter MID and TID");
          return;
        }
      }
      if (
        this.posts.settlementCycle == "" ||
        this.posts.settlementCycle.length == 0 ||
        this.posts.settlementCycle == "undefined"
      ) {
        alert("Please select settlement cycle");
        return;
      }
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        window.scrollTo(0, 0);
        return;
      } else {
        this.loading = true;
        let formData = new FormData();
        /*
             Add the form data we need to submit
         */
        formData.append("branchIDSel", JSON.stringify(this.posts.branchIDSel));
        formData.append("branchManger", this.posts.branchManagerID.restEmpID);
        formData.append("empType", this.posts.empType);
        formData.append("licenseNO", this.posts.licenseNO);
        formData.append("addressInput", this.posts.addressInput);
        formData.append("address", this.posts.address);
        formData.append("state", this.posts.state);
        formData.append("city", this.posts.city);
        formData.append("pincode", this.posts.pincode);
        formData.append("country", this.posts.country);
        formData.append("latitude", this.posts.latitude);
        formData.append("longitude", this.posts.longitude);
        formData.append("restaurantID", this.posts.restaurantID.restID);
        formData.append("restaurantName", this.posts.restaurantID.name);
        formData.append("invoicePrefix", this.posts.invoicePrefix);
        formData.append("invoiceMessage", this.posts.invoiceMessage);
        formData.append("gstNO", this.posts.gstNO);
        formData.append("gstName", this.posts.gstName);
        formData.append("restCharges", this.posts.restCharges);
        formData.append(
          "avgPreparationTime",
          this.posts.avgPreparationTime.time
        );
        formData.append("startTime", JSON.stringify(this.posts.startTime));
        formData.append("endTime", JSON.stringify(this.posts.endTime));
        formData.append("closed", JSON.stringify(this.posts.closed));
        formData.append("corpBranchID", this.posts.corpBranchID.corpBranchID);
        formData.append("restaurantType", this.posts.restType.restTypeID);
        formData.append("document", this.encodeImage);
        formData.append("isPublicOrder", this.posts.isPublicOrder);
        formData.append("isSodexoApproved", this.posts.isSodexoApproved);
        formData.append("mid", this.posts.mid);
        formData.append("tid", this.posts.tid);
        formData.append("payu_qr_id", this.posts.payu_qr_id);
        formData.append("revenue_share", this.posts.revenue_share);
        formData.append("settlement_cycle_id", this.posts.settlementCycle.id);
        formData.append("bankName", this.posts.bankName);
        formData.append("accountNO", this.posts.accountNO);
        formData.append("ifscCode", this.posts.ifscCode);
        formData.append("licenseExpiryDate", this.autoClose);
        formData.append("metaTitle", this.posts.metaTitle);
        formData.append("metaDescription", this.posts.metaDescription);
        formData.append("metaKeywords", this.posts.metaKeywords);
        formData.append("otherDocs", JSON.stringify(this.otherDocs));
        formData.append("branchManName", this.posts.branchManName);
        formData.append("branchManMob", this.posts.branchManMob);
        formData.append("branchManEmail", this.posts.branchManEmail);
        formData.append("createdBy", this.$storageData.profile.pid);
        formData.append("loginTypeID", this.$storageData.login_type);

        //e.preventDefault();
        this.axios
          .post(this.$loggedRole+"/addRestaurantBranch", formData)
          .then(result => {
            if (result.data.status == 200) {
              this.status = result.data.data;

              this.showDismissibleAlert = true;
              this.loading = false;

              setTimeout(() => {
                this.$router.push({ name: "restaurantBranchTable" });
              }, 2500);
            } else {
              this.$swal({
                title: 'Oops...',
                text: result.data.message,
                icon: "warning",
              });
            }
          })
          .catch(error => {
            this.loading = false;
            this.$swal({
              title: 'Oops...',
              text: error.response.data.message,
              icon: "error",
            });
          });
      }
    },
    getAllBranchDetails() {
      this.axios
        .get(this.$loggedRole+"/getAllBranchDetails", {
          params: {
            empID: this.$storageData.profile.pid,
            empTypeID: this.$storageData.profile.empTypeID
          }
        })
        .then(result => {
          this.branches = result.data.data;
        });
    },

    getRestaurantList() {
      this.axios
        .post(this.$loggedRole+"/getRestaurantList", {
          restID: this.$storageData.profile.restID,
          restBranchID: this.$storageData.profile.restBranchID,
          empID: this.$storageData.profile.pid,
          empTypeID: this.$storageData.profile.empTypeID,
          city: this.$storageData.profile.city
        })
        .then(result => {
          this.restaurants = result.data.data;
        });
    },

    previewImage: function(event) {
      // Reference to the DOM input element
      var input = event.target;
      // Ensure that you have a file before attempting to read it
      if (input.files && input.files[0]) {
        // create a new FileReader to read this image and convert to base64 format
        var reader = new FileReader();
        // Define a callback function to run, when FileReader finishes its job
        reader.onload = e => {
          // Note: arrow function used here, so that "this.imageData" refers to the imageData of Vue component
          // Read image as base64 and set to imageData
          this.imageData = e.target.result;
        };
        // Start the reader job - read file as a data url (base64 format)
        reader.readAsDataURL(input.files[0]);
        this.encodeImage = input.files[0];
        this.previewFileName = input.files[0].name;
        this.docExt = /[^/]*$/.exec(this.encodeImage.type)[0];
      }
    },
    uploadImage(event, index) {
      var input = event.target;
      if (input.files && input.files[0]) {
        this.otherDocs[index].docFileName = input.files[0].name;
        var reader = new FileReader();
        reader.readAsDataURL(input.files[0]);
        let formData = new FormData();

        formData.append("document", input.files[0]);
        // let headers = authHeader();
        this.axios
          .post(this.$loggedRole+"/imageUploadToBucketNormalSize", formData, {
            'Content-Type': 'multipart/form-data'
          })
          .then(result => {
            this.otherDocs[index].documentImage = result.data.data;
          })
          .catch(error => {
            this.$swal({
              title: 'Oops...',
              text: error.response.data.message,
              icon: "error",
            });
          });
      }
    },

    getRestaurantBranchByID(restBranchID) {
      this.axios
        .post(this.$loggedRole+"/getRestaurantBranchByID", { restBranchID: restBranchID })
        .then(result => {
          this.isEdit = true; //!this.Edit;

          let restBranchData = result.data.data;
          this.posts.restType = restBranchData.restaurantType;
          if (this.posts.restType.restTypeID == 1) {
            this.posts.corpBranchID = restBranchData.corpBranchID;
          }
          if (restBranchData.restaurantType == 2) {
            this.posts.corpBranchID = restBranchData.corpData;
          }
          this.posts.restaurantID = restBranchData.restaurantBrand;
          this.posts.gstNO = restBranchData.gstNO;
          this.posts.addressInput = restBranchData.address;
          this.posts.gstName = restBranchData.gstName;
          // this.posts.restCharges = restBranchData.restCharges;
          this.posts.ifscCode = restBranchData.ifscCode;
          this.autoClose = restBranchData.licenseExpiryDate;
          this.posts.latitude = restBranchData.latitude;
          this.posts.longitude = restBranchData.longitude;
          this.posts.accountNO = restBranchData.accountNO;
          this.posts.bankName = restBranchData.bankName;
          this.posts.branchManagerID = restBranchData.managerData;
          this.posts.state = restBranchData.state;
          this.posts.city = restBranchData.city;
          this.posts.pincode = restBranchData.pincode;
          this.posts.avgPreparationTime = restBranchData.avgPreparationTime;
          this.posts.isPublicOrder =
            restBranchData.isPublicOrder === 1 ? true : false;
          this.posts.licenseNO = restBranchData.licenseNO;
          this.posts.branchIDSel = restBranchData.branchData;
          this.posts.startTime = restBranchData.startTime;
          this.posts.endTime = restBranchData.endTime;
          this.posts.closed = restBranchData.closed;
          this.addressID = restBranchData.addressID;
          this.posts.metaKeywords = restBranchData.metaKeywords;
          this.posts.metaDescription = restBranchData.metaDescription;
          this.posts.metaTitle = restBranchData.metaTitle;
          this.imageData = this.prevLicenseDoc =
            restBranchData.licenseDoc != null ? restBranchData.licenseDoc : "";
          this.otherDocs = restBranchData.otherDocs;
          this.posts.invoicePrefix = restBranchData.invoicePrefix;
          this.posts.invoiceMessage = restBranchData.invoiceMessage;
          this.posts.isSodexoApproved =
            restBranchData.isSodexoApproved === 1 ? true : false;
          this.posts.mid = restBranchData.mid;
          this.posts.tid = restBranchData.tid;
          this.posts.payu_qr_id = restBranchData.payu_qr_id;
          this.posts.revenue_share = restBranchData.revenue_share;
          this.posts.settlementCycle = restBranchData.settlement_cycle_id;

          // this.getEmployees();
          // this.posts.endTime =
        });
    },

    updateRestaurantBrachForm() {
      if (this.posts.isSodexoApproved) {
        if (
          this.posts.mid == "" ||
          this.posts.mid.length == 0 ||
          this.posts.mid == "undefined" ||
          this.posts.tid == "" ||
          this.posts.tid.length == 0 ||
          this.posts.tid == "undefined"
        ) {
          alert("Please enter MID and TID");
          return;
        }
      }

      if (
        this.posts.settlementCycle == "" ||
        this.posts.settlementCycle.length == 0 ||
        this.posts.settlementCycle == "undefined"
      ) {
        alert("Please select settlement cycle");
        return;
      }
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        this.loading = true;
        let formData = new FormData();
        formData.append("restBranchID", sessionStorage.getItem("restBranchID"));
        formData.append("branchIDSel", JSON.stringify(this.posts.branchIDSel));
        formData.append("branchManger", this.posts.branchManagerID.restEmpID);
        formData.append("licenseNO", this.posts.licenseNO);
        formData.append("addressInput", this.posts.addressInput);
        formData.append("address", this.posts.address);
        formData.append("state", this.posts.state);
        formData.append("city", this.posts.city);
        formData.append("pincode", this.posts.pincode);
        formData.append("country", this.posts.country);
        formData.append("latitude", this.posts.latitude);
        formData.append("longitude", this.posts.longitude);
        formData.append("restaurantID", this.posts.restaurantID.restID);
        formData.append("invoicePrefix", this.posts.invoicePrefix);
        formData.append("invoiceMessage", this.posts.invoiceMessage);
        formData.append("gstNO", this.posts.gstNO);
        formData.append("gstName", this.posts.gstName);
        // formData.append('restCharges',this.posts.restCharges);
        formData.append(
          "avgPreparationTime",
          this.posts.avgPreparationTime.time
        );
        formData.append("startTime", JSON.stringify(this.posts.startTime));
        formData.append("endTime", JSON.stringify(this.posts.endTime));
        formData.append("closed", JSON.stringify(this.posts.closed));
        formData.append("corpBranchID", this.posts.corpBranchID.corpBranchID);
        formData.append("restaurantType", this.posts.restType.restTypeID);
        formData.append("document", this.encodeImage);
        formData.append("prevDocument", this.prevLicenseDoc);
        formData.append("isPublicOrder", this.posts.isPublicOrder);
        formData.append("isSodexoApproved", this.posts.isSodexoApproved);
        formData.append("mid", this.posts.mid);
        formData.append("tid", this.posts.tid);
        formData.append("payu_qr_id", this.posts.payu_qr_id);
        formData.append("revenue_share", this.posts.revenue_share);
        formData.append("settlement_cycle_id", this.posts.settlementCycle.id);
        formData.append("bankName", this.posts.bankName);
        formData.append("accountNO", this.posts.accountNO);
        formData.append("ifscCode", this.posts.ifscCode);
        formData.append("licenseExpiryDate", this.autoClose);
        formData.append("createdBy", this.$storageData.profile.pid);
        formData.append("loginTypeID", this.$storageData.login_type);
        formData.append("addressID", this.addressID);
        formData.append("metaTitle", this.posts.metaTitle);
        formData.append("metaDescription", this.posts.metaDescription);
        formData.append("metaKeywords", this.posts.metaKeywords);
        formData.append("otherDocs", JSON.stringify(this.otherDocs));

        this.axios
          .post(this.$loggedRole+"/updateRestaurantBranchForm", formData)
          .then(result => {
            this.status = result.data.data;

            this.showDismissibleAlert = true;
            this.loading = false;

            setTimeout(() => {
              this.$router.push({ name: "restaurantBranchTable" });
            }, 2000);
          })
          .catch(error => {
            this.loading = false;
            this.$swal({
              title: 'Oops...',
              text: error.response.data.message,
              icon: "error",
            });
          });
      }
    },

    AddItem() {
      this.otherDocs.push({
        documentNO: "",
        documentImage: 0,
        documentName: "",
        documentExpiry: "",
        docFileName: ""
      });
      //console.log(this.menuAddons);
    },
    /**
     * Delete the row from form
     */
    deleteItem(index) {
      // groupID is dishAddonID from ck_dish_addons
      if (confirm("Are you sure you want to delete this element?")) {
        this.otherDocs.splice(index, 1);
      }
    }
  },
  mounted() {
    // console.log(this.$route);
    if (this.$route.params.type == "edit") {
      if (this.$route.params.id) {
        sessionStorage.setItem("restBranchID", this.$route.params.id);
      }
    } else {
      sessionStorage.setItem("restBranchID", 0);
    }
    // alert(sessionStorage.getItem('restBranchID'));

    if (sessionStorage.getItem("restBranchID") > 0) {
      this.getRestaurantBranchByID(sessionStorage.getItem("restBranchID"));
    }
    this.getAllBranchDetails();
    this.getRestaurantList();
    this.getCorporateList();
    this.getSettlementCycleTypes();
    this.$refs.address.focus();
  },
  middleware: "authentication"
};
</script>
<style lang="scss" scoped>
img.preview {
  width: 100px;
  height: 100px;
  background-color: white;
  border: 1px solid #ddd;
  padding: 5px;
  object-fit: contain;
  margin-top: 6px;
}

.file-upload-form {
  padding: 5px 10px;
  background: #ececec;
  border: 1px solid #ececec;
  position: relative;
  color: black;
  border-radius: 2px;
  text-align: center;
  float: left;
  cursor: pointer;
}

.uploadInput {
  position: absolute;
  z-index: 1000;
  opacity: 0;
  cursor: pointer;
  right: 0;
  top: 0;
  height: 100%;
  font-size: 24px;
  width: 100%;
}

.dayClass {
  margin-top: 35px;
}

.alertMsg {
  position: fixed;
  right: 0px;
  z-index: 500;
  top: 79px;
  width: 22%;
}
</style>
